import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { ResourceMethod } from 'ngx-resource/src/Interfaces';
import { IQueryInput, IReservation } from '../interfaces/index';


@Injectable()
@ResourceParams({
})
export class ReservationsService extends BaseService {


  @ResourceAction({
    method: RequestMethod.Post,
    auth: true,
    path: '/reservations'
  })
  post: ResourceMethod<IReservation, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    auth: true,
    path: '/reservations'

  })
  get: ResourceMethod<IQueryInput, IReservation[]>;

  @ResourceAction({
    method: RequestMethod.Put,
    auth: true,
    path: '/cancel-reservations/{!id}'
  })
  update: ResourceMethod<IReservation, IReservation>;


  @ResourceAction({
    method: RequestMethod.Put,
    auth: true,
    path: '/accept-reservations/{!id}'
  })
  acceptReservations: ResourceMethod<IReservation, IReservation>;

  @ResourceAction({
    method: RequestMethod.Put,
    auth: true,
    path: '/refuse-reservations/{!id}'
  })
  refuseReservations: ResourceMethod<IReservation, IReservation>;

  @ResourceAction({
    method: RequestMethod.Put,
    auth: true,
    path: '/cancel-reservations/{!id}'
  })
  cancelReservations: ResourceMethod<IReservation, IReservation>;

  @ResourceAction({
    method: RequestMethod.Put,
    isArray: true,
    auth: true,
    path: '/delete-reservations/{!id}'
  })
  deleteReservations: ResourceMethod<IReservation, IReservation>;

  @ResourceAction({
    method: RequestMethod.Get,
    // isArray: true,
    // auth: true,
    path: '/reservations/{!id}'
  })
  getReservation: ResourceMethod<{id: number}, any>;

}
