import { Injectable, EventEmitter } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoadingController, AlertController, ToastController, ModalController } from '@ionic/angular';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { IUser } from '../interfaces/i-user';
import { AbstractControl } from '@angular/forms';
import { TermOfUseModalPage } from '../pages/term-of-use-modal/term-of-use-modal.page';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public translate: TranslatePipe = null;

  public languageChanged$: EventEmitter<string>;
  private language: string = null;

  public userChanged$: EventEmitter<IUser>;
  private user: IUser;

  constructor(
    public router: Router,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public toastController: ToastController,
    public modalController: ModalController
    // public translate: TranslatePipe
  ) {
    this.languageChanged$ = new EventEmitter();
    this.userChanged$ = new EventEmitter();
  }

  public getLanguage(): string {
    return this.language;
  }

  public changeLanguage(language: string): void {
    this.language = language;
    this.languageChanged$.emit(language);
  }

  public getUser(): IUser {
    return this.user;
  }

  public changeUser(user: IUser): void {
    this.user = user;
    this.userChanged$.emit(user);
  }

  handleError(error) {
    {
      this.hideLoading();
      let message = '';
      if (error.status === 401) {
        message = this.translate.transform('ACCESS_UNAUTHORIZED_ERROR');
        this.showAlert(message);
      } else if (error.status === 500) {
        message = this.translate.transform('SERVER_ERROR');
        this.showAlert(message);
      } else if (error.status === 404) {
        message = this.translate.transform('CHECK_SERVER');
        this.showAlert(message);
      } else if (error.status === 403) {
        localStorage.removeItem('token');
        this.router.navigateByUrl('/login');
      } else if (error.status === 400) {
         // message = this.translate.transform('NOTIFICATION_WILL_NOT_BE_RECEIVED');
        message = this.translate.transform('CHECK_DATA');      
        this.showAlert(message);
      } else if (error.status === 409) {
        message = this.translate.transform('CHECK_MAIL');
        this.showAlert(message);
      } else {
        message = this.translate.transform('CHECK_INTERNET_HISTORY');
        this.showAlert(message);
        if (typeof error.code !== 'undefined') {
          message = +' (' + error.code + ')';
        }
      }
    }
  }

  async showLoading() {
    const msg: string = this.translate.transform('LOADING');
    return await this.loadingController.create({
      message: msg
    }).then(loader => {
      loader.present().then(() => {

      });
    });
  }

  async showLoader(translated) {
    return await this.loadingController.create({
      message: translated
    }).then(loader => {
      loader.present().then(() => {

      });
    });
  }

  async hideLoading() {
    return await this.loadingController.dismiss().then(() => console.log('loading dismissed'));
  }

  async showAlert(msg) {
    const alert = await this.alertController.create({
      header: this.translate.transform('ERROR'),
      message: msg,
      buttons: [{
        text: this.translate.transform('RETRY'),
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      }]
    });
    await alert.present();
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 7000
    });
    toast.present();
  }

  async presentAlert(msg, headerText, buttonText, url) {
    const alert = await this.alertController.create({
      header: headerText,
      message: msg,
      buttons: [{
        text: buttonText,
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          if (url !== null) {
            this.router.navigateByUrl(url);
          }
          console.log('Confirm Cancel');
        }
      }]
    });
    await alert.present();
  }

  async termOfUseModal() {
    const assetsModal = await this.modalController.create({
      component: TermOfUseModalPage,
      componentProps: {
      }
    });
    assetsModal.onDidDismiss().then((data: any) => {
      console.log('data', data);
    });
    await assetsModal.present();
  }

  static mustBeChecked(c: AbstractControl): { [key: string]: boolean } {
    let rv: { [key: string]: boolean } = {};
    if (!c.value) {
      rv['notChecked'] = true;
    }
    return rv;
  }
}
