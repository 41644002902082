import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction, ResourceMethod } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { IUser, IQueryInput } from '../interfaces';

@Injectable()
@ResourceParams({
  auth: true
})
export class NanniesService extends BaseService {

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/nannies'
  })
  query: ResourceMethod<any, IUser[]>;

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/nannies'
  })
  filterNannies: ResourceMethod<IQueryInput, IUser[]>;

  @ResourceAction({
    method: RequestMethod.Get,
    path: '/nannies/{!id}'
  })
  getNanny: ResourceMethod<{id: number}, any>;
}
