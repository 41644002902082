import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { ResourceAction, ResourceMethod } from 'ngx-resource';
import { IUser } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SearchNannyService extends BaseService {

  @ResourceAction({
    isArray: true,
    path: '/nannies'
  })
  query: ResourceMethod<any, IUser[]>;
}
