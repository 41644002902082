import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { ResourceMethod } from 'ngx-resource/src/Interfaces';


@Injectable()
@ResourceParams({
})
export class FavoriteNanniesService extends BaseService {


  @ResourceAction({
    method: RequestMethod.Post,
    path: '/favorite-nannies'
  })
  post: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/favorite-nannies'

  })
  getAllFavoites: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Put,
    // isArray: true,
    path: '/favorite-nannies/{id}'
  })
  put: ResourceMethod<any, any>;

}

