/**
  * Usage: dateString | localDate:'format'
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
    name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

    constructor() {

    }

    transform(value: any, format: string) {
        if (!value) { return ''; }
        if (!format) { format = 'shortDate'; }
        const language = localStorage.getItem('language');
        return formatDate(value, format, language);
    }
}
