import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'welcome', loadChildren: './pages/welcome/welcome.module#WelcomePageModule' },
  { path: 'nearby-nanny', loadChildren: './pages/nearby-nanny/nearby-nanny.module#NearbyNannyPageModule' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'nanny-profile/:nannyId', loadChildren: './pages/nanny-profile/nanny-profile.module#NannyProfilePageModule' },
  { path: 'register-family', loadChildren: './pages/register-family/register-family.module#RegisterFamilyPageModule' },
  { path: 'register-nanny', loadChildren: './pages/register-nanny/register-nanny.module#RegisterNannyPageModule' },
  { path: 'register-enterprise', loadChildren: './pages/register-enterprise/register-enterprise.module#RegisterEnterprisePageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'reserve-nanny/:nannyId', loadChildren: './pages/reserve-nanny/reserve-nanny.module#ReserveNannyPageModule' },
  { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
  { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule' },
  { path: 'forget-password', loadChildren: './pages/forget-password/forget-password.module#ForgetPasswordPageModule' },
  { path: 'edit-family-profile', loadChildren: './pages/edit-family-profile/edit-family-profile.module#EditFamilyProfilePageModule' },
  { path: 'edit-enterprise-profile',
   loadChildren: './pages/edit-enterprise-profile/edit-enterprise-profile.module#EditEnterpriseProfilePageModule' },
  { path: 'edit-nanny-profile', loadChildren: './pages/edit-nanny-profile/edit-nanny-profile.module#EditNannyProfilePageModule' },
  { path: 'search-nanny', loadChildren: './pages/search-nanny/search-nanny.module#SearchNannyPageModule' },
  { path: 'family-payment-means', loadChildren: './pages/family-payment-means/family-payment-means.module#FamilyPaymentMeansPageModule' },
  { path: 'about-us', loadChildren: './pages/about-us/about-us.module#AboutUsPageModule' },
  { path: 'family-reservations', loadChildren: './pages/family-reservations/family-reservations.module#FamilyReservationsPageModule' },
  { path: 'nanny-reviews/:nannyId',
   loadChildren: './pages/nanny-reviews/nanny-reviews.module#NannyReviewsPageModule' },
  { path: 'archived-reservations',
   loadChildren: './pages/archived-reservations/archived-reservations.module#ArchivedReservationsPageModule' },
  { path: 'cancel-reservation/:reservationId', loadChildren: './pages/cancel-reservation/cancel-reservation.module#CancelReservationPageModule' },
  { path: 'nanny-reservations', loadChildren: './pages/nanny-reservations/nanny-reservations.module#NannyReservationsPageModule' },
  { path: 'new-payment/:reservationId/:cardId', loadChildren: './pages/new-payment/new-payment.module#NewPaymentPageModule' },
  { path: 'add-card', loadChildren: './pages/add-card/add-card.module#AddCardPageModule' },
  { path: 'notification', loadChildren: './pages/notification/notification.module#NotificationPageModule' },
  { path: 'means-of-payment/:reservationId', loadChildren: './pages/means-of-payment/means-of-payment.module#MeansOfPaymentPageModule' },
  { path: 'change-password', loadChildren: './pages/change-password/change-password.module#ChangePasswordPageModule' },
  { path: 'payments', loadChildren: './pages/payments/payments.module#PaymentsPageModule' },
  { path: 'payment/:paymentId', loadChildren: './pages/payment/payment.module#PaymentPageModule' },
  { path: 'favorites-nanny', loadChildren: './pages/favorites-nanny/favorites-nanny.module#FavoritesNannyPageModule' },
  { path: 'my-cards', loadChildren: './pages/my-cards/my-cards.module#MyCardsPageModule' },
  { path: 'payment-subscription', loadChildren: './pages/payment-subscription/payment-subscription.module#PaymentSubscriptionPageModule' },
  { path: 'subscription-history', loadChildren: './pages/subscription-history/subscription-history.module#SubscriptionHistoryPageModule' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
