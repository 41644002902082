import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-term-of-use-modal',
  templateUrl: './term-of-use-modal.page.html',
  styleUrls: ['./term-of-use-modal.page.scss'],
  providers: [TranslatePipe]
})
export class TermOfUseModalPage implements OnInit {

  constructor(public translate: TranslatePipe,
    public modalController: ModalController
  ) {
    console.log('TermsOfUseModalPage');

  }

  ngOnInit() {
    console.log('TermsOfUseModalPage', 'ngOnInit');
  }

  ionViewDidEnter() {
    console.log('TermsOfUseModalPage', 'ionViewDidEnter');
  }

  dismissModal() {
    this.modalController.dismiss(null);
  }
}
