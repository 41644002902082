import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction, ResourceMethod } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { IQueryInput, IPayment, ICard } from '../interfaces/index';

@Injectable()
@ResourceParams({
  auth: true
})
export class PaymentsService extends BaseService {

  @ResourceAction({
    isArray: true,
    path: '/payments'
  })
  query: ResourceMethod<IQueryInput, IPayment[]>;

  @ResourceAction({
    path: '/payments/{!id}'
  })
  get: ResourceMethod<{ id: number }, IPayment>;

  @ResourceAction({
    method: RequestMethod.Post,
    path: '/payments'
  })
  save: ResourceMethod<IPayment, IPayment>;

  @ResourceAction({
    method: RequestMethod.Put,
    path: '/payments/{!id}'
  })
  update: ResourceMethod<IPayment, IPayment>;

  @ResourceAction({
    method: RequestMethod.Delete,
    path: '/payments/{!id}'
  })
  remove: ResourceMethod<{ id: number }, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    path: '/payments/reorder'
  })
  reorder: ResourceMethod<{ ids: Array<number> }, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    path: '/make-payment'
  })
  make: ResourceMethod<IPayment, IPayment>;

  @ResourceAction({
    method: RequestMethod.Post,
    path: '/user-payment/{id}'
  })
  getUser: ResourceMethod<IPayment, IPayment>;

  @ResourceAction({
    method: RequestMethod.Post,
    isArray: true,
    path: '/cards'
  })
  saveCard: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/Cards'
  })
  getCard: ResourceMethod<any, IPayment[]>;
  @ResourceAction({
    method: RequestMethod.Put,
    isArray: true,
    path: '/cards/remove/{id}'
  })
  removeCard: ResourceMethod<{ id: number }, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    // isArray: true,
    path: '/cards/{id}'
  })
  getCardById: ResourceMethod<{ id: number }, ICard>;
}

