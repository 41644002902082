import { Injectable } from '@angular/core';
import { ResourceParams, ResourceAction, ResourceMethod } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { IQueryInput, IAvailablePlace } from '../interfaces';
import { BaseService } from './base.service';

@Injectable()
@ResourceParams({
  auth: true
})
export class AvailablePlacesService extends BaseService {

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/available-places'
  })
  getAvailablePlaces: ResourceMethod<IQueryInput, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    isArray: true,
    path: '/available-places'
  })
  addAvailablePlaces: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Put,
    isArray: true,
    path: '/available-places/{!id}'
  })
  updateAvailablePlaces: ResourceMethod<IAvailablePlace, any>;

  @ResourceAction({
    method: RequestMethod.Delete,
    isArray: true,
    path: '/available-places/{!id}'
  })
  deleteAvailablePlaces: ResourceMethod<{ id: number }, any>;
}
