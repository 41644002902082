
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction, ResourceMethod } from 'ngx-resource';
import { IUser } from '../interfaces/index';

@Injectable()
@ResourceParams({
  auth: true
})
export class NannyProfileService extends BaseService {

  @ResourceAction({
    isArray: true,
    path: '/profile'
  })
  query: ResourceMethod<any, IUser[]>;

  @ResourceAction({
    isArray: true,
    path: '/all-countries'
  })
  getAllCountries: ResourceMethod<any, any>;
}
