import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction, ResourceMethod } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { IQueryInput, IPackage } from '../interfaces/index';

@Injectable()
@ResourceParams({
  auth: true
})
export class PackagesService extends BaseService {

  @ResourceAction({
    isArray: true,
    path: '/all-packages'
  })
  query: ResourceMethod<IQueryInput, IPackage[]>;

  @ResourceAction({
    path: '/package/{!id}'
  })
  get: ResourceMethod<{ id: number }, IPackage>;

}

