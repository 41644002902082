import { Component, OnInit } from '@angular/core';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { appConfig } from './app.config';
import { Router, NavigationExtras } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { CommonService } from './services';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { Device } from '@ionic-native/device/ngx';
import { PushDevicesService } from './services/push-devices.service';
import { Location } from '@angular/common';
import { FCM } from '@ionic-native/fcm/ngx';
import { IUser, IDevice } from './interfaces';
// import { Deeplinks } from '@ionic-native/deeplinks';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  providers: [TranslatePipe]
})
export class AppComponent implements OnInit {

  public firebaseToken: string;
  public languages: any;
  public currentLanguage: any = null;
  public user: IUser = null;
  public color = '';
  public direction = 'portrait';
  public topic = 'reservationStatus';

  public appPages = [
    {
      title: 'RESERVE_NANNY',
      url: '/search-nanny',
      // url: '/home',
      icon: 'custom-reservation-3',
      userType: 'FAMILY',
      // class: 'inactive'
    },
    {
      title: 'MY_RESERVATIONS',
      url: '/family-reservations',
      // url: '/home',
      icon: 'custom-reservation-2',
      userType: 'FAMILY',
      // class: 'inactive'
    },
    {
      title: 'MY_RESERVATIONS',
      url: '/nanny-reservations',
      // url: '/home',
      icon: 'custom-reservation-2',
      userType: 'NANNY',
      // class: 'inactive'
    },
    {
      title: 'MY_PROFILE',
      url: '/edit-family-profile',
      icon: 'custom-profile-1',
      userType: 'FAMILY',
    },
    {
      title: 'MY_PROFILE',
      url: '/edit-nanny-profile',
      icon: 'custom-profile-1',
      userType: 'NANNY',
    },
    {
      title: 'MY_PROFILE',
      url: '/edit-enterprise-profile',
      icon: 'custom-profile-1',
      userType: 'ENTERPRISE',
    },
    {
      title: 'MY_PAYMENT_MEANS',
      url: '/family-payment-means',
      // url: '/home',
      icon: 'custom-payment-3',
      userType: 'FAMILY',
      // class: 'inactive'
    },
    {
      title: 'MY_NANNIES',
      url: '/family-nannies',
      // url: '/home',
      icon: 'custom-nanny-4',
      userType: 'FAMILY',
      // class: 'inactive'
    },
    {
      title: 'MY_PAYMENTS',
      url: '/payments',
      // url: '',
      icon: 'custom-payment-3',
      userType: 'FAMILY',
      class: 'active'
    },
    {
      title: 'INVIT_MY_CONTACTS',
      url: '/family-invit-my-contacts',
      // url: '/home',
      icon: 'custom-invitation-2',
      userType: 'FAMILY',
      // class: 'inactive'
    },
    {
      title: 'ABOUT',
      url: '/about-us',
      icon: 'custom-group-1',
      userType: 'ALL',
    },
    {
      title: 'LOGOUT',
      url: '/logout',
      icon: 'exit',
      userType: 'ALL',
    }
  ];

  public config = appConfig;
  public pushes: any = [];

  constructor(
    public translateService: TranslateService,
    public platform: Platform,
    public splashScreen: SplashScreen,
    public push: Push,
    public device: Device,
    public alertCtrl: AlertController,
    public statusBar: StatusBar,
    public navCtrl: NavController,
    public router: Router,
    public pushDevicesService: PushDevicesService,
    public commonService: CommonService,
    public location: Location,
    public fcm: FCM
  ) {
    this.initializeApp();
    this.languages = appConfig.languages;
    this.currentLanguage = localStorage.getItem('language');
    if (typeof this.currentLanguage === 'undefined' || this.currentLanguage === null) {
      this.currentLanguage = this.languages[0].code;
    }
    this.translateService.use(this.currentLanguage);
    this.commonService.languageChanged$.subscribe(langCode => this.onLanguageChanged(langCode));
    this.commonService.userChanged$.subscribe(user => this.onUserChanged(user));
  }

  onLanguageChanged(langCode) {
    console.log('AppComponent', 'onLanguageChanged', langCode);
    this.currentLanguage = langCode;
  }

  onUserChanged(user) {
    console.log('AppComponent', 'onUserChanged', user);
    this.user = user;
    if ((typeof this.user !== 'undefined') && this.user != null) {
      if (typeof this.user.userType !== 'undefined') {
        this.color = this.user.userType.toLowerCase();
      }
    }
  }

  localeInitializer(localeId: string): Promise<any> {
    console.log('AppComponent', 'localeInitializer', localeId);
    return import(`@angular/common/locales/${localeId}.js`).then(module => {
      console.log(localeId, 'imported');
      registerLocaleData(module.default);
    });
  }

  goToPage(url) {
    console.log('goToPage', url);
    this.router.navigateByUrl(url);
  }

  ngOnInit() {
    console.log('AppComponent', 'ngOnInit');
  }

  ngAfterViewInit() {
    console.log('AppComponent', 'ngAfterViewInit');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      console.log('AppComponent', 'initializeApp');
      this.statusBar.styleDefault();
      setTimeout(() => {
        this.splashScreen.hide();
        this.initFirebaseNotification();
      }, 1000);

      if (typeof localStorage.getItem('language') !== 'undefined' && localStorage.getItem('language') !== null) {
        const language = localStorage.getItem('language');
        this.translateService.setDefaultLang(language);
        this.currentLanguage = language;
        this.localeInitializer(language).then(() => {
        });
      } else {
        localStorage.setItem('language', 'fr');
        this.translateService.setDefaultLang('fr');
        this.currentLanguage = 'fr';
        this.localeInitializer('fr').then(() => {
        });
      }
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      const offlineRoutes = ['/login', '/forget-password', '/register', '/register-family', '/register-nanny', '/register-enterprise'];
      if (token != null && typeof token !== 'undefined' && user != null && typeof user !== 'undefined') {
        this.goToPage('/home');
        const userObject = JSON.parse(user);
        this.commonService.changeUser(userObject as IUser);
      } else {
        if (offlineRoutes.indexOf(this.location.path()) > -1) {
          this.goToPage(this.location.path());
        } else {
          this.goToPage('/welcome');
        }
      }
    });
  }

  initPushDevice() {
    console.log('initPushDevice');
    // to check if we have permission
    this.push.hasPermission().then((res: any) => {
      if (res.isEnabled) {
        console.log('We have permission to send push notifications');
      } else {
        console.log('We do not have permission to send push notifications');
        // add request permission of push notif
      }
    });
    if (!this.platform.is('cordova')) {
      console.warn('Push notifications not initialized. Cordova is not available - Run in physical device');
      return;
    }
    const options: PushOptions = {
      android: {
        senderID: 'YOUR_SENDER_ID'
      },
      ios: {
        alert: 'true',
        badge: false,
        sound: 'true'
      },
      windows: {

      },
      browser: {
        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
      }
    };
    const pushObject: PushObject = this.push.init(options);

    pushObject.on('registration').subscribe((data: any) => {
      console.log('device registrationId -> ' + data.registrationId);
      const token = localStorage.getItem('token');
      if (token !== null && typeof token !== 'undefined') {
        const pushDevice = {
          os: this.device.platform.toLowerCase(),
          model: this.device.model,
          uuid: this.device.uuid,
          version: this.device.version,
          token: data.registrationId
        };
        this.pushDevicesService.add(pushDevice).$observable.subscribe((result) => {
          console.log('pushDevicesService.add', result);
        });
        localStorage.removeItem('registrationId');
      } else {
        localStorage.setItem('registrationId', data.registrationId);
      }
    });

    pushObject.on('notification').subscribe((data: any) => {
      console.log('data -> ' + data);
      // if user using app and push notification comes
      if (data.additionalData.foreground) {
        // if application open, show popup
        const alert = this.alertCtrl.create({
          header: 'New Notification',
          message: data.message,
          buttons: [{
            text: 'Ignore',
            role: 'cancel'
          }, {
            text: 'View',
            handler: () => {
              const token = localStorage.getItem('token');
              if (token !== null && typeof token !== 'undefined') {
                this.router.navigateByUrl(data.route);
                console.log('Push notification clicked');
              } else {
                localStorage.setItem('redirectTo', data.route);
              }
            }
          }]
        });
        alert.then();
      } else {
        // if user NOT using app and push notification comes
        const token = localStorage.getItem('token');
        if (token !== null && typeof token !== 'undefined') {
          this.router.navigateByUrl(data.route);
          console.log('Push notification clicked');
        } else {
          localStorage.setItem('redirectTo', data.route);
        }
      }
    });

    pushObject.on('error').subscribe(error => console.error('Error with Push plugin' + error));
  }

  initFirebaseNotification() {
    const user = localStorage.getItem('user');
    console.log('initFirebaseNotification - user: ' + user);
    this.fcm.onNotification().subscribe(data => {
      console.log('initFirebaseNotification - onNotification: ', JSON.stringify(data));
      if (data.wasTapped) {
        console.log('Received in background');
        this.pushes.push({
          body: data.message,
          title: data.title
        });
      } else {
        console.log('Received in foreground');
        this.pushes.push({
          body: data.message,
          title: data.title
        });
        console.log(this.pushes);
      }
      const userConnected = localStorage.getItem('userConnected');
      if (userConnected == 'true') {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            special: JSON.stringify(this.pushes)
          }
        };

        this.router.navigate(['notification'], navigationExtras);
      } else {
        this.router.navigate(['/login']);
        localStorage.setItem('notification-page', 'notification');
        localStorage.setItem('notification-pushes', JSON.stringify(this.pushes));
      }
    });
    this.fcm.onTokenRefresh().subscribe(token => {
      console.log('onTokenRefresh - token: ', token);
      this.pushDevicesService.get().$observable.subscribe((devices) => {
        for (let i = 0; i < devices.length; i++) {
          if (this.device.uuid.toString().trim() == devices[i].uuid.toString().trim()) {
            this.updateDeviceToken(token, devices[i].id);
          }
        }
      });
    });
    if (user != null && typeof user != 'undefined') {
      console.log('initFirebaseNotification - user != null ');

    }
  }

  getToken(update, id) {
    console.log('initFirebaseNotification - getToken');
    this.fcm.getToken().then(token => {
      console.log('getToken - token: ' + token);
      if (!update) {
        this.saveDeviceToken(token);
      } else {
        this.updateDeviceToken(token, id);
      }
    });
  }

  saveDeviceToken(newToken) {
    if (newToken != null && typeof newToken != 'undefined') {
      console.log('initFirebaseNotification - saveDeviceToken: ' + newToken);
      const pushDevice: IDevice = {
        id: this.user.id,
        os: this.device.platform.toLowerCase(),
        model: this.device.model,
        uuid: this.device.uuid,
        version: this.device.version,
        token: newToken
      };
      this.pushDevicesService.add(pushDevice).$observable.subscribe((result: Array<any>) => {
        console.log('pushDevicesService - success: ' + result);
      }, (error) => {
        console.log('pushDevicesService - error: ' + error);
      });
      localStorage.removeItem('registrationId');
    } else {
      localStorage.setItem('registrationId', newToken);
    }
  }

  updateDeviceToken(newToken, id) {
    if (newToken !== null && typeof newToken !== 'undefined') {
      const pushDevice: IDevice = {
        id: id,
        os: this.device.platform.toLowerCase(),
        model: this.device.model,
        uuid: this.device.uuid,
        version: this.device.version,
        token: newToken
      };
      this.pushDevicesService.update(pushDevice).$observable.subscribe((result) => {
        console.log('pushDevicesService.add', result);
      });
      localStorage.removeItem('registrationId');
    } else {
      localStorage.setItem('registrationId', newToken);
    }
  }

  subscribeToTopic(topic) {
    console.log('initFirebaseNotification - subscribeToTopic: ' + topic);
    this.fcm.subscribeToTopic(topic);
  }

  unsubscribeFromTopic(topic) {
    this.fcm.unsubscribeFromTopic(topic);
  }

}
