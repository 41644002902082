import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ResourceModule } from 'ngx-resource';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Push } from '@ionic-native/push/ngx';
import { IonicRatingModule } from 'ionic4-rating';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ApplicationPipesModule } from './pipes/application-pipes.module';
import { Stripe } from '@ionic-native/stripe/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { TermOfUseModalPage } from './pages/term-of-use-modal/term-of-use-modal.page';


@NgModule({
    declarations: [
        AppComponent,
        TermOfUseModalPage
    ],
    exports: [
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        ResourceModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        IonicRatingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ApplicationPipesModule,


    ],
    bootstrap: [AppComponent],
    entryComponents: [
        AppComponent,
        TermOfUseModalPage
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Platform,
        Device,
        Push,
        NativeStorage,
        FileTransfer,
        Camera,
        Crop,
        File,
        FilePath,
        Geolocation,
        NativeGeocoder,
        Stripe,
        FCM
    ]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

