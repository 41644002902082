import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { ResourceMethod } from 'ngx-resource/src/Interfaces';
import { IDevice } from '../interfaces';

@Injectable()
@ResourceParams({
  auth: true
})
export class PushDevicesService extends BaseService {

  @ResourceAction({
    method: RequestMethod.Post,
    isArray: true,
    path: '/push-devices'
  })
  add: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Put,
    isArray: true,
    path: '/push-devices/{!id}'
  })
  update: ResourceMethod<IDevice, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/push-devices'    
  })
  get: ResourceMethod<any, any>;
}
