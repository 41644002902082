import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { ResourceMethod } from 'ngx-resource/src/Interfaces';
import { IQueryInput, IRating } from '../interfaces/index';


@Injectable()
@ResourceParams({
})
export class NannyReviewsService extends BaseService {


  @ResourceAction({
    method: RequestMethod.Post,
    path: '/nanny-reviews'
  })
  post: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/nanny-reviews'

  })
  get: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    path: '/nanny-reviews/{!id}'

  })
  getById: ResourceMethod<any, any>;

}
