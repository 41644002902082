export const appConfig: any = {
    appName: 'NannyNonStop',
    devBaseUrl: 'https://nanny-non-stop.continuousnet.com',
    testBaseUrl: 'https://nanny-non-stop.continuousnet.com',
    prodBaseUrl: 'https://api.nannynonstop.com',
    env: 'dev', // prod dev test
    apiUrl: '/api',
    dbName: 'NannyNonStop.db',
    dbLocation: 'default',
    appVersion: '1.0.0',
    stripeKey: 'pk_test_iuX1HyLLphdu5Hu4dRZxlq650031PjlLQ9',
    languages: [{
      name: 'Français',
      code: 'fr',
      flag: '',
      default: true
    }, {
      name: 'English',
      code: 'en',
      flag: '',
      default: false
    }]
  };
