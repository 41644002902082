import { Injectable } from '@angular/core';
import { ResourceParams, ResourceAction, ResourceMethod } from 'ngx-resource';
import { BaseService } from './base.service';
import { RequestMethod } from '@angular/http';
import { IAsset, IQueryInput } from '../interfaces';

@Injectable()
@ResourceParams({
  auth: true
})
export class AssetsService extends BaseService {

  @ResourceAction({
    isArray: true,
    path: '/assets'
  })
  query: ResourceMethod<IQueryInput, IAsset[]>;

  @ResourceAction({
    path: '/assets/{!id}'
  })
  get: ResourceMethod<{ id: number }, IAsset>;

  @ResourceAction({
    method: RequestMethod.Post,
    path: '/assets'
  })
  save: ResourceMethod<FormData, IAsset>;

  @ResourceAction({
    method: RequestMethod.Put,
    path: '/assets/{!id}'
  })
  update: ResourceMethod<IAsset, IAsset>;

  @ResourceAction({
    method: RequestMethod.Delete,
    path: '/assets/{!id}'
  })
  remove: ResourceMethod<{ id: number }, any>;

  @ResourceAction({
    isArray: true,
    path: '/user-assets/{!userId}'
  })
  getAllNannyAssets: ResourceMethod<IQueryInput, IAsset[]>;
   
}
