import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Resource, ResourceActionBase } from 'ngx-resource';
import { appConfig } from '../../app/app.config';

@Injectable()
export class BaseService extends Resource {

  constructor(
    public http: Http,
  ) {
    super(http);
  }

  $getPath(methodOptions?: ResourceActionBase): string | Promise<any> {
    const resPath = super.$getPath();
    // console.log('resPath', resPath);
    return resPath;
  }

  getBaseUrl() {
    if (appConfig.env === 'dev') {
      return appConfig.devBaseUrl;
    } else if (appConfig.env === 'prod') {
      return appConfig.prodBaseUrl;
    }
  }

  $getUrl(methodOptions?: ResourceActionBase): string | Promise<any> {
    const resUrl = super.$getUrl();
    const baseUrl = this.getBaseUrl();
    let url = `${baseUrl}`;
    if (typeof methodOptions.lang !== 'undefined' && methodOptions.lang) {
      url += '/' + localStorage.getItem('language');
    }
    url += `${appConfig.apiUrl}${resUrl}`;
    console.log('resUrl', url);
    return url;
  }

  $getHeaders(methodOptions?: any): any | Promise<any> {
    const headers = super.$getHeaders();
    return new Promise((resolve, reject) => {
      if (methodOptions.auth) {
        const token = localStorage.getItem('token');
        if (token !== null && typeof token !== 'undefined') {
          headers['Authorization'] = `Bearer ${token}`;
          // console.log('headers', headers);
          resolve(headers);
        } else {
          console.log('get Value error');
        }
      } else {
        console.log('headers before token', headers);
        resolve(headers);
      }
    });
  }

}
