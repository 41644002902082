import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction, ResourceMethod } from 'ngx-resource';
import { RequestMethod } from '@angular/http';

@Injectable()
@ResourceParams({
  auth: true
})
export class SubscriptionService extends BaseService {

  @ResourceAction({
    method: RequestMethod.Post,
    path: '/make-subscription'
  })
  make: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    path: '/user-subscription/{!id}'
  })
  get: ResourceMethod<{ id: number }, any>;

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/user-subscriptions'
  })
  query: ResourceMethod<any, any>;

}
