import { NgModule } from '@angular/core';
import { LocalDatePipe } from './local-date.pipe';

@NgModule({
    imports: [
    ],
    declarations: [
        LocalDatePipe,
    ],
    exports: [
        LocalDatePipe,
    ]
})
export class ApplicationPipesModule { }

