import { Injectable } from '@angular/core';
import { ResourceAction, ResourceMethod, ResourceParams } from 'ngx-resource';
import { IUser } from '../interfaces';
import { RequestMethod } from '@angular/http';
import { BaseService } from './base.service';

@Injectable()
@ResourceParams({
  auth: true
})
export class ProfileService extends BaseService {

  @ResourceAction({
    path: '/profile'
  })
  get: ResourceMethod<any, IUser>;

  @ResourceAction({
    method: RequestMethod.Put,
    path: '/profile'
  })
  update: ResourceMethod<IUser, IUser>;
}
