import { Injectable } from '@angular/core';
import { ResourceParams, ResourceAction, ResourceMethod } from 'ngx-resource';
import { BaseService } from './base.service';
import { RequestMethod } from '@angular/http';
import { IKid } from '../interfaces';

@Injectable()
@ResourceParams({
  auth: true
})
export class KidsService extends BaseService {

  @ResourceAction({
    method: RequestMethod.Get,
    isArray: true,
    path: '/kids'
  })
  getKids: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    isArray: true,
    path: '/kids'
  })
  addKid: ResourceMethod<IKid, any>;

  @ResourceAction({
    method: RequestMethod.Put,
    isArray: true,
    path: '/kids/{!id}'
  })
  updateKid: ResourceMethod<IKid, any>;

  @ResourceAction({
    method: RequestMethod.Delete,
    isArray: true,
    path: '/kids/{!id}'
  })
  deleteKid: ResourceMethod<{ id: number }, any>;
}
