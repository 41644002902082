import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResourceParams, ResourceAction } from 'ngx-resource';
import { RequestMethod } from '@angular/http';
import { ResourceMethod } from 'ngx-resource/src/Interfaces';

@Injectable()
@ResourceParams({
})
export class AuthService extends BaseService {

  @ResourceAction({
    method: RequestMethod.Post,
    auth: false,
    path: '/../auth/login'
  })
  login: ResourceMethod<any, any>;


  @ResourceAction({
    method: RequestMethod.Post,
    auth: false,
    lang: true,
    path: '/../auth/register'
  })
  registerFamilly: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    auth: false,
    lang: true,
    path: '/../auth/register'
  })
  registerNanny: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    auth: false,
    lang: true,
    path: '/../auth/register'
  })
  registerEnterprise: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    auth: false,
    path: '/../auth/register/confirm'
  })
  registerConfirm: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    auth: false,
    lang: true,
    path: '/../auth/reset/request'
  })
  resetRequest: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    auth: false,
    path: '/../auth/reset/confirm'
  })
  resetConfirm: ResourceMethod<any, any>;

  @ResourceAction({
    method: RequestMethod.Post,
    auth: true,
    path: '/profile/change-password'
  })
  changePassword: ResourceMethod<any, any>;

}

